import React from 'react';
import { withRouter } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import { connect, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import ProfileDetails from '../../accounts/profiles/Profile/ProfileDetails';
import PatientExams from './PatientExams';
import PatientRiskServices from './PatientRiskServices';
import MedicalHistory from '../../healthData/MedicalHistory';
import HealthData from '../../healthData/HealthData/HealthData';
import Card from '../../../components/layout/atoms/Card';

import { handlePatientLookUpOpen, handlePatientLookUpClose } from '../../../actions/controls';
import { getPatientDetails, getPatientExams, getPatientRiskServices } from '../../../actions/patients';
import { getMostRecentHealthData } from '../../../actions/healthData';


const PatientDetails = (props) => {
  const { patientDetails, staticWords } = props;

  return (
    <Grid container spacing={2} mt={1} sx={{ overflow: 'hidden' }}>
      <Grid item xs={12}>
        <PatientRiskServices />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid container item spacing={2} xs={12} lg={6}>
          <Grid item xs={12}>
            <FadeIn>
              <Card size="fill" sx={{ padding: (theme) => theme.spacing(3) }}>
                <ProfileDetails title={staticWords.Patient_Details} details={patientDetails} />
              </Card>
            </FadeIn>
          </Grid>
          <Grid item xs={12}>
            <FadeIn>
              <Card size="fill" sx={{ padding: (theme) => theme.spacing(3) }}>
                <MedicalHistory saveBtn={staticWords.Save} cancelBtn={staticWords.Cancel} />
              </Card>
            </FadeIn>
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12} lg={6}>
          <Grid item xs={12}>
            <FadeIn>
              <Card size="fill" sx={{ padding: (theme) => theme.spacing(3) }}>
                <PatientExams />
              </Card>
            </FadeIn>
          </Grid>
          <Grid item xs={12}>
            <FadeIn>
              <Card size="fill" sx={{ padding: (theme) => theme.spacing(3) }}>
                <HealthData />
              </Card>
            </FadeIn>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// FUNCTIONAL COMPONENT
const Patient = (props) => {
  const { patientDetails, getPatientExams, getPatientRiskServices, getPatientDetails, getMostRecentHealthData } = props;
  const staticWords = useSelector((state) => state.handlingTranslation.words);

  let { account_number } = props.match.params;

  // DO GET PATIENT BASED ON HYPERLINK
  React.useEffect(() => {
    if (!patientDetails || account_number !== patientDetails.account_number) {
      getPatientDetails(account_number).then((result) => {
        getPatientExams(result.id);
        // getPatientRiskServices(result.id);
        // this.props.getPatientMedicalHistory(result.id);
        getMostRecentHealthData(result.id);
      });
    }
    //route back from PatientRiskAnalysis has patientDetails but missing exams
    else if (patientDetails && !patientDetails.exams) {
      getPatientDetails(account_number).then((result) => {
        getPatientExams(result.id);
        // getPatientRiskServices(result.id);
        getMostRecentHealthData(result.id);
      });
    }
  }, []);

  // DISPLAY
  return (
    <div>
      {patientDetails === null || account_number !== patientDetails.account_number ? null : (
        <PatientDetails patientDetails={patientDetails} staticWords={staticWords} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
});

export default connect(mapStateToProps, {
  handlePatientLookUpOpen,
  handlePatientLookUpClose,
  getPatientDetails,
  getPatientExams,
  getPatientRiskServices,
  getMostRecentHealthData,
})(withRouter(Patient));